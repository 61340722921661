import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Spin, Alert, Modal, Form, Input, Button } from 'antd';
import { getLinkDetailByToken } from '../../apis/apis'
import { appConfig } from "../../config/config";
import Report from './Report'

const PasswordFormModal = ({ handlePasswordSubmit }) => (
    <Modal
        title="Password Required"
        open={true}
        footer={null}
        closable={false}
        maskClosable={false}
    >
        <Form
            onFinish={handlePasswordSubmit}
            layout="vertical"
        >
            <Form.Item
                name="password"
                label="Password"
                rules={[{ required: true, message: 'Please input the password!' }]}
            >
                <Input.Password placeholder="Enter password" />
            </Form.Item>
            <Form.Item>
                <Button className='bg-blue-600 mt-2.5' type="primary" htmlType="submit" block>
                    Submit
                </Button>
            </Form.Item>
        </Form>
    </Modal>
);


export const ShareDetail = () => {
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [studyData, setStudyData] = useState(null);
    const [queryToken, setToken] = useState('');
    const [password, setUserPassword] = useState('');
    
    useEffect(() => {
        const token = searchParams.get('token');
        if (token) {
            setToken(token);
            if (password && password.length > 0) {
                fetchStudyDetails(token, password);
            }

            const authToken = localStorage.getItem('token');
            if (!authToken || authToken.length === 0) {
                localStorage.setItem('token', token);
            }
        }
    }, [searchParams, password]);

    const fetchStudyDetails = async (token, password) => {
        try {
            setLoading(true);
            const resp = await getLinkDetailByToken(token, password);
            if (resp) {
                setStudyData(resp);
            }
        } catch (e) {
            setError(e);
            console.error(e);
        } finally {
            setLoading(false)
        }
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <Spin size="large" tip="Loading study details..." />
            </div>
        );
    }

    const handlePasswordSubmit = (password) => {
        setUserPassword(password.password);
    }

    if (!password || password.length === 0 || !studyData) {
        return <PasswordFormModal handlePasswordSubmit={handlePasswordSubmit} />
    }

    const studyID = studyData.study_id;
    return <Report
        studyLink={`${appConfig.viewerHost}ohif/viewer?StudyInstanceUIDs=${studyID}&token=${queryToken}`}
        closable={false}
        isOpen={studyID}
        studyId={studyID} />
};