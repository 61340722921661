import { Layout } from "antd";
import { Modal, Button, Typography } from "antd";
import { useAuth0 } from '@auth0/auth0-react';
import logo from '../../components/Navigations/logo.png'
const { Title, Paragraph } = Typography;

export const LoginPage = () => {
  const { loginWithRedirect } = useAuth0();
  const handleSignup = () => {
    loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup',
      },
    });
  }
  
  return (
    <Layout className="bg-white flex-row">
      <Modal
        open={true}
        onCancel={false}
        footer={null}
        className="w-[400px] h-[500px]"
        closable={false}
        centered
        bodyStyle={{ textAlign: "center" }}
      >
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%'
        }}>
          <img
            src={logo}
            className="w-60 border-0"
            alt="Creni Logo"
            style={{ margin: '0 auto' }}
          />
        </div>

        <Title level={3} className="mb-7 mt-7">
          Welcome to Creni.org
        </Title>

        <Paragraph className="mb-10 mb-10" style={{ marginBottom: 24 }}>
          Empowering Healthcare Professionals with <br />
          Seamless and Secure MRI
        </Paragraph>

        <div>
          <Button
            type="primary"
            onClick={() => loginWithRedirect()}
            size="large"
            className="w-[120px] mr-7 px-4 bg-blue-500 hover:bg-blue-600 text-white font-medium rounded-md border border-blue-500 transition duration-200 ease-in-out"
          >
            Login
          </Button>
          <Button onClick={() => handleSignup()} className="w-[120px]" size="large">Signup</Button>
        </div>
      </Modal>
    </Layout>
  );
};
