import { useEffect, useState } from 'react'
import { Table, Tooltip, Input, Button, Radio, Menu, Dropdown, Popconfirm, message, Typography, Modal } from "antd";
import { PlusOutlined, SettingOutlined } from '@ant-design/icons';
import { AIGatewayStore } from '../../../stores/ai'
import { getDicomNodes, createAiDicomNode, createAiDicomNodeCloud, deleteDicomAiNode } from '../../../apis/ai'
import { CreateDicomNodeModal } from './modals/CreateDicomNode'
import {
    listAllAIGatewayByUserID,
} from "../../../apis/gateway";
import { fetchGatewaysStatusAI } from '../../../hooks/GatewayStatusCustomHook';
import { EditGatewayModal } from "./modals/EditAIGatewayModal";
import { EllipsisOutlined } from '@ant-design/icons';
import { MODAL_EDIT_DICOM_NODE, MODAL_EDIT_RULE_CLICK } from '../../../../src/pages/constants/modals';
import { useContext } from "react";
import { AppContext } from '../../../libs/context';
import lodash from 'lodash';
import { DeleteRuleByGatewayIdAndRuleID, GetRuleAIByGatewayID, GetAllAiModel } from '../../../apis/ai'
import { isOfflineStatus } from '../../../libs/status'
const { Title } = Typography;
const { Search } = Input;

const aiGatewayColumns = [
    {
        title: "Name",
        dataIndex: "name",
        render: (_, record) => {
            return (
                <Tooltip placement="left" title={record.name}>
                    <p className='truncate w-[200px]'>{record.name}</p>
                </Tooltip>
            )
        },
    },
    {
        title: "Status",
        dataIndex: "status",
        className: "w-[150px]",
        render: (text, record) => {
            if (isOfflineStatus(record)) {
                return <p className='text-red-500'>{record.status}</p>
            }
            return <p>{record.status}</p>;
        },
    },
    {
        title: "IP",
        className: "w-[100px]",
        render: (text, record) => {
            return <Tooltip placement="left" title={record.listener_ip}>
                <p className='truncate w-[200px]'>{record.listener_ip}</p>
            </Tooltip>
        },
    },
    {
        title: "AI Listener Port",
        render: (text, record) => {
            return <Tooltip placement="left" title={record.listener_port}>
                <p className='truncate'>{record.listener_port}</p>
            </Tooltip>
        },
    },
    {
        title: "AET",
        dataIndex: "aet",
        className: "w-[100px]",
        render: (text, record) => {
            return <p>{record.listener_aet}</p>;
        },
    },
    {
        title: "AI Listener",
        dataIndex: "ai-listener",
        className: "w-[150px]",
        render: (text, record) => {
            return <p>{record.listener_status}</p>;
        },
    },
    {
        title: "Role",
        dataIndex: "role",
        className: "w-[100px]",
        render: (text, record) => {
            return <p>{record.type}</p>;
        },
    }
]

const fixedDestinationDicomNodesColumns = [
    {
        title: "Name",
        dataIndex: "name",
        className: "w-[150px]",
        render: (text, record) => {
            return <p>{record.name}</p>;
        },
    },
    {
        title: "IP",
        dataIndex: "ip",
        className: "w-[150px]",
        render: (text, record) => {
            return <p>{record.ip}</p>;
        },
    },
    {
        title: "Port",
        dataIndex: "port",
        className: "w-[100px]",
        render: (text, record) => {
            return <p>{record.port}</p>;
        },
    },
    {
        title: "AET",
        dataIndex: "aet",
        className: "w-[150px]",
        render: (text, record) => {
            return <p>{record.aet}</p>;
        },
    },
    {
        title: "Status",
        dataIndex: "status",
        className: "w-[150px]",
        render: (text, record) => {
            if (isOfflineStatus(record)) {
                return <p className='text-red-500'>{record.status}</p>
            }
            return <p>{record.status}</p>;
        },
    }
]

const dicomTagLabels = {
    '00081030': 'Study Description',
    '0008103E': 'Series Description',
    '00080060': 'Modality',
    "00180015": 'Body Part Examined',
    '00080052': 'Query/Retrieve Level',
    "custom": "Custom",
};


const GatewaysTable = ({ selectedGateway, gatewayData, columns, pagination }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const filteredDataGateways = gatewayData.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const filteredAdminEditGatewayOnly = gatewayData && gatewayData.filter((gateway) => {
        return gateway.type === "admin";
    });

    const [isShowGatewayManage, setShowEditGateway] = useState(false);
    const isSelectedAdminGateway = selectedGateway && selectedGateway.type === 'admin';
    return (<div><div className="flex mb-2.5">
        <Title level={4} className='mr-2.5'>AI Gateways</Title>
        <Search
            placeholder="Search gateways by name"
            onSearch={value => setSearchTerm(value)}
            style={{ width: 400 }}
        />
        {filteredAdminEditGatewayOnly.length !== 0 && isSelectedAdminGateway && gatewayData && gatewayData.length !== 0 && (
            <Button icon={<SettingOutlined />}
                onClick={() => {
                    setShowEditGateway(!isShowGatewayManage);
                }} className='ml-2.5' >Manage Gateways</Button>
        )}
    </div>
        <Table
            columns={columns}
            pagination={pagination}
            rowKey="id"
            dataSource={filteredDataGateways}
        />
        {filteredAdminEditGatewayOnly.length !== 0 && gatewayData &&
            gatewayData.length !== 0 &&
            <EditGatewayModal
                isOpen={isShowGatewayManage}
                handleClose={() => {
                    setShowEditGateway(!isShowGatewayManage);
                }}
                data={filteredAdminEditGatewayOnly}
            />}
    </div >)
}

const DicomNodesTable = ({ dicomNodes, pagination, selectedGatewayID, onCreateDicomNodeClick, disabledCreateDicom, columns }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const filteredDataDicoms = dicomNodes.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="mt-2.5">
            <div className="flex flex-col mb-3">
                <div className='flex flex-row'>
                    <Title level={4} className='mr-2.5'>Destination DICOM Nodes</Title>
                    <Search placeholder="Search DICOM node by name" onSearch={value => setSearchTerm(value)} style={{ width: 400 }} />
                    <Button disabled={!selectedGatewayID || disabledCreateDicom} onClick={onCreateDicomNodeClick} icon={<PlusOutlined />} className="ml-2.5">Create DICOM Node</Button>
                </div>
                <p className='text-sm text-gray-600'>Note: Al prediction results send to the destination DICOM Nodes. You can set multiple destinations.</p>
            </div>

            <Table
                pagination={pagination}
                columns={columns}
                rowKey="id"
                dataSource={filteredDataDicoms}
            />
        </div>
    )
}

export const Gateways = () => {
    const { store, setCurrentModal } = useContext(AppContext)
    const { getAiDicomNodes, setAiDicomNodes, gatewayData, setGatewayData, selectedSourceGatewayId, setSourceGatewayId, setSelectedSourceGateway, selectedSourceGateway } = store;
    const { modelsData, addModelData } = store;
    const { aiRules, setAiRules } = store;
    const [selectedRowKey, setSelectedRowKey] = useState(null);
    const dicomNodes = getAiDicomNodes();

    const aiColumns = [{
        title: "",
        key: "ai_gateway_selection",
        width: "15px",
        className: "border-solid border-r-[1px]",
        render: (text, record) => (
            <Radio
                data-testid={`gateway-${record.listener_ip}`}
                checked={record.id === selectedRowKey}
                onChange={() => handleRadioChange(record)}
            />
        ),
    },
    ...aiGatewayColumns
    ]
    const handleRadioChange = (record) => {
        setSelectedSourceGateway(record)
        setSelectedRowKey(record.id);
        setSourceGatewayId(record.id);
        fetchDicomNodes(record.id);
    };

    const fetchDicomNodes = async (gatewayId) => {
        try {
            if (gatewayId == null) return;
            const response = await getDicomNodes(gatewayId);
            const data = response.data || [];
            setAiDicomNodes(data);
        } catch (error) {
            console.error("Error fetchDicomNodes", error);
        }
    }

    const [isLoadingGateway, setLoadingGateway] = useState(false);
    const fetchGatewayAndStatus = async () => {
        try {
            if (isLoadingGateway) return;
            setLoadingGateway(true);

            const response = await listAllAIGatewayByUserID();
            if (!response.data) return;

            const gatewayWithStatus = await fetchGatewaysStatusAI(response.data);
            setGatewayData(gatewayWithStatus)

        } catch (e) {
            console.error(e);
        } finally {
            setLoadingGateway(false);
        }
    }

    useEffect(() => {
        fetchGatewayAndStatus();
    }, []);

    useEffect(() => {
        // init auto select first 0 gateway
        if (selectedRowKey === null && gatewayData && gatewayData.length > 0) {
            setSelectedRowKey(gatewayData[0].id);
            setSourceGatewayId(gatewayData[0].id);
            fetchDicomNodes(gatewayData[0].id);
            setSelectedSourceGateway(gatewayData[0]);
        }
    }, [gatewayData])

    useEffect(() => {
        if (!dicomNodes || dicomNodes.length === 0) {
            fetchDicomNodes(selectedSourceGatewayId);
        }
    }, [selectedSourceGatewayId])

    const innerTablePagination = {
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'],
    };

    const [isCreateDicomNodeModalVisible, setIsCreateDicomNodeModalVisible] = useState(false);
    const onCreateDicomNode = async (data) => {
        try {
            await createAiDicomNode(data);
            fetchDicomNodes(selectedSourceGatewayId);

            message.success("Dicom node create success");
        } catch (error) {
            console.error("Error onCreateDicomNode", error);
            message.error("Failed create dicom node");
        }
    }

    const onCreateDicomNodeCloud = async (gateway_id) => {
        try {
            await createAiDicomNodeCloud(gateway_id);
            fetchDicomNodes(selectedSourceGatewayId);

            message.success("Dicom node create success");
        } catch (error) {
            console.error("Error onCreateDicomNodeCloud", error);
            message.error("Failed create dicom node cloud");
        }
    }


    const deleteDicomNode = async (node_id) => {
        try {
            await deleteDicomAiNode(selectedSourceGatewayId, node_id);
            fetchDicomNodes(selectedSourceGatewayId);
            message.success("Dicom node deleted success");
        } catch (e) {
            console.error(e);
            message.error("Failed delete dicom node");
        }
    }

    const [selectedDicomNode, setSelectedDicomNode] = useState(null);

    const toggleEditModal = () => {
        const data = {
            ...selectedDicomNode,
            gateway_id: selectedSourceGatewayId,
            node_id: selectedDicomNode.id,
        }

        setCurrentModal({
            modal: MODAL_EDIT_DICOM_NODE,
            data: data,
        });
    }

    const dropDownMenu = (data) => {
        const isCloud = data.name === "CLOUD" &&
            data.ip === null &&
            data.port === null &&
            data.aet === null &&
            data.transferSyntax === null &&
            data.retrieve === null;

        setSelectedDicomNode(data);

        return <Menu>
            {!isCloud && <Menu.Item key="edit-ai-dicom" onClick={toggleEditModal}>Manage Gateway</Menu.Item>}
            <Menu.Item key="delete-ai-dicom">
                <Popconfirm
                    title="Do you want to delete?"
                    okButtonProps={{ className: "bg-red-600" }}
                    onConfirm={() => {
                        deleteDicomNode(data.id);
                    }}
                >
                    Delete
                </Popconfirm>
            </Menu.Item>
        </Menu>
    }

    const destinationDicomNodeColumns = [
        ...fixedDestinationDicomNodesColumns,
        {
            title: "Action",
            dataIndex: "action",
            className: "w-[50px]",
            render: (_, record) => {
                return (
                    <Dropdown dropdownRender={() => {
                        return dropDownMenu(record);
                    }} trigger={['click']}>
                        <Button icon={<EllipsisOutlined />} />
                    </Dropdown>
                )
            }
        },
    ]


    const onEditRuleClick = (data) => {
        setCurrentModal({
            modal: MODAL_EDIT_RULE_CLICK,
            data,
        });
    }

    const onDeleteRuleClick = (data) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this rule?',
            content: 'This action cannot be undone.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                try {
                    await DeleteRuleByGatewayIdAndRuleID(selectedSourceGatewayId, data.id);
                    message.success("Rule deleted");
                    setTimeout(() => {
                        window.location.reload(true);
                    }, 500)
                } catch (error) {
                    message.error("Failed to delelete rule");
                    console.error('Failed to delete rule:', error);
                }
            },
        });
    }

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
        },
        {
            title: "AI Name",
            dataIndex: "model",
            render: (_, record) => {
                const aiName = lodash.get(record, `model.name`);
                return <p className='text-wrap w-[300px]'>{aiName}</p>
            },
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (_, record) => {
                if (!record.children) {
                    return <></>
                }

                return <div className='flex'>
                    <Button onClick={() => onEditRuleClick(record)} className='mr-2.5'>Edit</Button>
                    <Button onClick={() => onDeleteRuleClick(record)} danger>Delete</Button>
                </div>
            }
        },
    ];

    useEffect(() => {
        const fetchModels = async () => {
            const resp = await GetAllAiModel(true);
            if (resp.data) {
                resp.data.forEach((data) => {
                    addModelData({
                        [data.id]: data,
                    });
                })
            }
        }

        fetchModels();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if (!selectedSourceGatewayId) return;
            const result = await GetRuleAIByGatewayID(selectedSourceGatewayId);
            setAiRules([]) // clear previous data
            if (result.data && result.data.length > 0) {
                const updatedData = result.data.map((data) => {
                    const children = data.tags.map((data) => {
                        return {
                            name: dicomTagLabels[data.key] || "N/A",
                            model: {
                                name: data.value,
                            }
                        }
                    });

                    return {
                        ...data,
                        model: modelsData[data.model_ai_id],
                        children,
                    }
                });
                setAiRules(updatedData);
            }
        }

        fetchData();
    }, [selectedSourceGatewayId, modelsData]);

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: aiRules.length,
    });

    const handleTableChange = (pagination) => {
        setPagination(pagination);
    };


    return (
        <div>
            <div>
                {isCreateDicomNodeModalVisible &&
                    <CreateDicomNodeModal onSubmit={(data) => {
                        if (data.type && data.type === "cloud") {
                            onCreateDicomNodeCloud(selectedSourceGatewayId);
                        } else {
                            onCreateDicomNode({
                                ...data,
                                gateway_id: selectedSourceGatewayId,
                            });
                        }
                    }} onClose={() => {
                        setIsCreateDicomNodeModalVisible(!isCreateDicomNodeModalVisible)
                    }} />}

                <GatewaysTable
                    selectedGateway={selectedSourceGateway}
                    gatewayData={gatewayData}
                    columns={aiColumns}
                    pagination={innerTablePagination}
                />
            </div>

            <DicomNodesTable
                dicomNodes={dicomNodes}
                pagination={innerTablePagination}
                selectedGatewayID={selectedSourceGatewayId}
                onCreateDicomNodeClick={() => setIsCreateDicomNodeModalVisible(!isCreateDicomNodeModalVisible)}
                disabledCreateDicom={selectedSourceGateway && selectedSourceGateway.type === "user"}
                columns={destinationDicomNodeColumns}
            />

            <div className='w-full mt-2.5'>
                <div className='w-full flex flex-col mb-2.5 mt-2.5'>
                    <div className='flex'>
                        <Title level={4} className='mr-2.5'>Rules</Title>
                        <Button onClick={onEditRuleClick} icon={<PlusOutlined />}>
                            Create Rule
                        </Button>
                    </div>
                    <p className='text-sm text-gray-600'>Note: You can customize forward rules to Al processors based on DICOM Tags.</p>
                </div>
                <Table
                    columns={columns}
                    pagination={{
                        current: pagination.current,
                        pageSize: pagination.pageSize,
                        total: pagination.total,
                        showSizeChanger: true,
                        pageSizeOptions: ['2', '10', '20', '50'],
                        onChange: handleTableChange,
                    }}
                    rowKey="id"
                    dataSource={aiRules}
                />
            </div>
        </div>

    )
};
