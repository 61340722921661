import { useEffect, useState, useContext } from 'react';
import { Worklist } from './Worklist'
import { AppContext } from '../../../libs/context';
import { GetWorklistByGatewayID } from '../../../apis/ai'

export const GatewayWorkList = ({ onViewLog }) => {
    const [worklist, setWorklist] = useState([]);
    const { store } = useContext(AppContext)
    const { getSelectedWorklistGatewayId } = store;
    const selectedGatewayId = getSelectedWorklistGatewayId();
    useEffect(() => {
        const fetchData = async () => {
            if (!selectedGatewayId) return;
            const response = await GetWorklistByGatewayID(selectedGatewayId);
            if (response.data) {
                setWorklist(response.data);
            }
        };

        fetchData();
    }, [selectedGatewayId])
    
    return <div><Worklist allowResend={true} requiredSelectGateway={true} worklist={worklist} onViewLog={onViewLog} /></div>
};
