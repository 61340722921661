import { useState } from "react";
import { Layout, Divider, Tabs, Modal, Button } from "antd";
import { AiModels } from './pages/Model';
import { DeveloperWorkList } from './pages/DeveloperWorklist';
import { message } from 'antd';

export const AIDeveloperPage = () => {
    const [currentLog, setCurrentLog] = useState('');
    const [logModalVisible, setLogModalVisible] = useState(false);
    const items = [
        {
            key: 'ai-models',
            label: 'AI Models',
            children: <AiModels onViewLog={() => {
                setLogModalVisible(true);
                setCurrentLog(message);
            }} />,
        },
        {
            key: 'developer-worklist',
            label: 'Worklist',
            children: <DeveloperWorkList onViewLog={(message) => {
                setLogModalVisible(true);
                setCurrentLog(message);
            }} />,
        },
    ];

    return (
        <Layout className="bg-white flex-row flex-col">
            <div className="p-4 max-w-screen-2xl mx-0 center mx-auto">
                <h1 className="text-2xl font-bold text-center mb-4">AI Developer</h1>
                <Divider />
                <span>* You can manage AI models, and worklist</span>
                <Tabs defaultActiveKey="ai-developers" items={items} />
            </div>
            {logModalVisible && <Modal
                title="Job Logs"
                visible={logModalVisible}
                onCancel={() => setLogModalVisible(false)}
                footer={[
                    <Button key="close" onClick={() => setLogModalVisible(false)}>
                        Close
                    </Button>,
                ]}
            >
                <p>{currentLog}</p>
            </Modal>}
        </Layout>
    );
};
