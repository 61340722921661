import { Layout } from "antd";

const ViewDetailLayout = ({ children }) => {
  return (
    <Layout className="bg-dark">
      <Layout className="bg-dark">{children}</Layout>
    </Layout>
  );
};
export default ViewDetailLayout;
