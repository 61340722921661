import { Layout } from "antd";
import { useMemo } from 'react';
import NavLogged from "../components/Navigations/NavLogged";
import Footer from "../components/Footer/Footer";
import { useState } from "react";
import { ChangeInformationModal } from "./modal/ChangeInformationModal"
import { ChangePasswordModal } from "./modal/ChangePasswordModal"
import { RequestAccessModal } from "./modal/RequestAccess";
import { EditDicomNodeModal } from "./modal/EditDicomNode";
import { ManageJobs } from './modal/ManageJobsModal/ManageJobs'
import { EditGatewayModal } from "./modal/EditGateway/EditGatewayModal";
import { useEffect } from 'react'
import SelectGatewayAndDicomModal from './modal/SelectDicomAndGatewayModal'
import { ManageNodeModal } from "./modal/ManageNode";
import AIRuleModal from './modal/AIRuleModal'
import AIModal from './modal/AIModal'
import { MODAL_RAISE_ACCESS, MODAL_EDIT_DICOM_NODE, MODAL_EDIT_GATEWAY, MODAL_CREATE_EDIT_AI_MODEL, MODAL_MANGE_DICOM_NODE, MODAL_SHOW_GATEWAY_DICOM_MANAGE, MODAL_MANAGE_JOBS_MODAL, MODAL_EDIT_RULE_CLICK } from '../../src/pages/constants/modals';
import { updateUserPassword, updateUserInformation } from '../apis/apis'
import { getTokenData } from "../libs/jwt";
import {
  requestPermission,
} from "../apis/admin";
import { message } from "antd";
import { useContext } from 'react';
import { AppContext } from '../libs/context';
import { useNavigate } from 'react-router-dom';
import { fetchGatewaysStatus } from '../hooks/GatewayStatusCustomHook';
import { fetchDicomNodes } from '../hooks/DicomNodeCustomHook';
import {
  CLOUD_PACS_MODE,
  LISTENER_ONLINE_STATUS,
} from "../pages/constants/dicomNode";
import lodash from 'lodash';

import {
  listAllGatewayByUserID,
} from "../apis/gateway";

const CloudPacs = {
  id: CLOUD_PACS_MODE,
  name: "Cloud PACS System",
  status: LISTENER_ONLINE_STATUS,
  listener_ip: "N/A",
  listener_status: "N/A",
  gateway_ip: "N/A",
  type: 'N/A'
};



const useGatewayManager = (
  initialGateways = [],
  setGateways,
  setLoading,
  setSelectedGateway,
  dependencies = []
) => {
  // Initial fetch
  useMemo(() => {
    const fetchInitialGateways = async () => {
      if (!initialGateways || initialGateways.length === 0) {
        setLoading?.(true);
        try {
          const response = await listAllGatewayByUserID();
          if (!response.data) {
            setGateways([]);
            return;
          }
          const gatewayWithStatus = await fetchGatewaysStatus(response.data);
          setGateways(gatewayWithStatus);
        } catch (error) {
          console.error('Failed to fetch gateways:', error);
          setGateways([]);
        } finally {
          setLoading?.(false);
        }
      } else {
        setGateways(initialGateways);
      }
    };

    fetchInitialGateways();
  }, [initialGateways.length]); // Run once on mount

  // Status update
  useEffect(() => {
    const updateGatewayStatus = async () => {
      if (!initialGateways?.length) return;

      try {
        const gatewayWithStatus = await fetchGatewaysStatus(initialGateways);
        setGateways(gatewayWithStatus);
      } catch (error) {
        console.error('Failed to update gateway status:', error);
      }
    };

    updateGatewayStatus();
  }, [...dependencies]);
};


const DashboardLayout = ({ children, onClose }) => {
  const navigate = useNavigate();
  const tokenData = getTokenData();
  const { currentModal, setCurrentModal, store } = useContext(AppContext);
  const [messageApi, contextHolder] = message.useMessage();

  const replaceRoute = (url) => {
    navigate(url, { replace: true });
  };

  const onRequestSubmit = async (data) => {
    try {
      await requestPermission({
        permission_ids: data.permission_ids,
        node_id: data.node_id,
        gateway_id: data.gateway_id,
      });
      message.success("Request permission success");
      setTimeout(() => {
        window.location.reload(true);
      }, 1000);
    } catch (e) {
      console.error(e);
      const errMessage = lodash.get(e, "response.data.message", null);
      if (errMessage) {
        message.error(errMessage);
      } else {
        message.error("Failed to request permission please double check error");
      }
    }
  };

  const [isPasswordShowModal, setIsPasswordModalVisible] = useState(false);
  const [isChangePersonalModal, setIsPersonalModalVisible] = useState(false);

  const onChangePersonalInformationClick = () => {
    setIsPersonalModalVisible(!isChangePersonalModal)
  }

  const onChangePasswordClick = () => {
    setIsPasswordModalVisible(!isPasswordShowModal);
  }

  const onAdminControlClick = () => {
    replaceRoute('/dashboard/admin-control');
  }

  const onAIDeveloperClick = () => {
    replaceRoute('/dashboard/ai-developer');
  }

  const onSubmitChangeInformation = async (data) => {
    try {
      const resp = await updateUserInformation(data);
      if (resp && resp.data) {
        message.success("Change information success");
      }
    } catch (e) {
      console.error(e);
    }
  }

  const onSubmitChangePassword = async (data) => {
    try {
      if (!data.confirmPassword) return;
      await updateUserPassword(data.confirmPassword);
      message.success("Change password success");
    } catch (e) {
      console.error(e);
      message.error("Failed to change password");
    }
  }

  const onModalClose = () => {
    setCurrentModal({
      modal: null,
      data: null,
    });
  }


  const refetchCurrentGatewayStatus = async (gateways) => {
    try {
      if (!gateways || gateways.length === 0) return;
      const gatewayWithStatus = await fetchGatewaysStatus(gateways);
      return gatewayWithStatus;
    } catch (e) {
      console.error(e);
      return [];
    }
  }


  const {
    setGatewayData,
    setSelectedDestinationGateway,
    setSourceGatewayId,
    setSourceDicomeNodeID,
    setDestinationGateways,
    selectedSourceGatewayId,
    selectedDestinationGatewayId,
    setDestinationDicome } = store;

  const { destinationDicomNodes, sourceDicomNodes } = store;
  const { gatewayData, destinationGateways } = store;
  const { selectedDestinationDicomId, selectedSourceDicomNodeId } = store
  const { getLoadingSourceGateway, getLoadingDestinationGateway, getLoadingSourceDicomNodes, getLoadingDestinationDicomNodes, setDestinationDicomNodes, setSourceDicomNodes } = store;
  const { modelsData } = store;
  const isSourceGatewayLoading = getLoadingSourceGateway();
  const isDestinationGatewayLoading = getLoadingDestinationGateway();

  const isSourceDicomLoading = getLoadingSourceDicomNodes();
  const isDestinationDicomLoading = getLoadingDestinationDicomNodes();

  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.clear();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  
  const fetchDicomNode = async (currentSelectedGateway, onDone) => {
    try {
      if (!currentSelectedGateway) return;
      const data = await fetchDicomNodes(currentSelectedGateway);
      onDone(data);
    } catch (e) {
      onDone([]);
      messageApi.open({
        key: `gateway-dicome-listing-${currentSelectedGateway.id}`,
        type: 'error',
        content: e.message || 'failed to fetch dicom node',
      });
    }
  }


  // Custom hook for fetching DICOM nodes
  const useDicomNodesLoader = (gatewayId, gateways, setLoading, setNodes, selectedDicomNode) => {
    useEffect(() => {
      const loadDicomNodes = async () => {
        if (!gatewayId) return;
        
        const selectedGateway = gateways?.find(
          (gateway) => gateway.id === gatewayId
        );

        if (!selectedGateway) return;

        try {
          setLoading(true);
          await fetchDicomNode(selectedGateway, (data) => {
            setNodes(data);
          });
        } catch (error) {
          console.error('Failed to fetch DICOM nodes:', error);
        } finally {
          setLoading(false);
        }
      };

      loadDicomNodes();
    }, [gatewayId, gateways.length, setLoading, setNodes, selectedDicomNode]);
  };
  const { setLoadingSourceGateway, setLoadingDestinationGateway, setLoadingSourceDicomNodes, setLoadingDestinationDicomNodes } = store;

  const { selectedSourceDicomNodeIdTemp, selectedDestinationDicomIdTemp } = store;
  const { setSourceDicomeNodeIDTemp, setDestinationDicomeTemp } = store;
  const { selectedSourceGatewayIdTemp, selectedDestinationGatewayIdTemp } = store;
  const { setSourceGatewayIdTemp, setSelectedDestinationGatewayTemp } = store;

  // Source gateways
  useGatewayManager(
    gatewayData,
    setGatewayData,
    setLoadingSourceGateway,
    setSourceGatewayIdTemp,
    [selectedSourceGatewayIdTemp]
  );

  const isSelectedCloudPacs = selectedDestinationGatewayIdTemp === CLOUD_PACS_MODE;

  // Destination gateways
  useGatewayManager(
    destinationGateways,
    setDestinationGateways,
    setLoadingDestinationGateway,
    setSelectedDestinationGatewayTemp,
    [selectedDestinationGatewayIdTemp, isSelectedCloudPacs]
  );


  useDicomNodesLoader(
    selectedSourceGatewayIdTemp,
    gatewayData,
    setLoadingSourceDicomNodes,
    setSourceDicomNodes,
    selectedSourceDicomNodeIdTemp
  );

  // Destination DICOM nodes  
  useDicomNodesLoader(
    selectedDestinationGatewayIdTemp,
    destinationGateways,
    setLoadingDestinationDicomNodes,
    setDestinationDicomNodes,
    selectedDestinationDicomIdTemp
  );

  return (
    <Layout className="bg-white">
      {isChangePersonalModal && (
        <ChangeInformationModal onSubmit={onSubmitChangeInformation} onClose={onChangePersonalInformationClick} isVisible={isChangePersonalModal} />
      )}

      {isPasswordShowModal && (
        <ChangePasswordModal onSubmit={onSubmitChangePassword} onClose={onChangePasswordClick} isVisible={isPasswordShowModal} />
      )}
      {currentModal.modal === MODAL_RAISE_ACCESS && (
        <RequestAccessModal
          onClose={onModalClose}
          onSubmit={onRequestSubmit}
          data={{
            email: tokenData.email || "",
          }}
        />
      )}

      {currentModal.modal === MODAL_EDIT_GATEWAY && (
        <EditGatewayModal
          handleClose={onModalClose}
          data={currentModal.data}
        />
      )}

      {currentModal.modal === MODAL_MANGE_DICOM_NODE && (
        <ManageNodeModal
          handleClose={onModalClose}
          gateway_id={currentModal.data.gateway_id}
          data={currentModal.data.dicomNode}
        />
      )}

      {currentModal.modal === MODAL_SHOW_GATEWAY_DICOM_MANAGE && (
        <SelectGatewayAndDicomModal
          onCancel={onModalClose}
          isLoadingGateway={currentModal.data.type === 'source' ? isSourceGatewayLoading : isDestinationGatewayLoading}
          isLoadingDicom={currentModal.data.type === 'source' ? isSourceDicomLoading : isDestinationDicomLoading}
          selectedGatewayId={currentModal.data.type === 'source' ? selectedSourceGatewayIdTemp : selectedDestinationGatewayIdTemp}
          selectedDicomNodeId={currentModal.data.type === 'source' ? selectedSourceDicomNodeIdTemp : selectedDestinationDicomIdTemp}
          gatewayData={currentModal.data.type === 'source' ? gatewayData : [CloudPacs, ...(destinationGateways || [])]}
          dicomNodes={currentModal.data.type === 'source' ? sourceDicomNodes : destinationDicomNodes}
          onDicomSelect={(selectedDicomNodeId) => {
            if (currentModal.data.type === 'source') {
              setSourceDicomeNodeIDTemp(selectedDicomNodeId);
            } else {
              setDestinationDicomeTemp(selectedDicomNodeId);
            }
          }}
          onGatewaySelect={(selectedGateway) => {
            if (currentModal.data.type === 'source') {
              if (selectedGateway.id !== selectedSourceGatewayIdTemp) {
                setSourceDicomeNodeID(null);
                setSourceDicomNodes([]);
              }
              setSourceGatewayIdTemp(selectedGateway.id)
            } else {
              if (selectedGateway.id !== selectedDestinationGatewayIdTemp) {
                setDestinationDicome(null);
                setDestinationDicomNodes([]);
              }
              setSelectedDestinationGatewayTemp(selectedGateway.id)
            }

          }}
          onSearchGatewayResult={async (gateways) => {
            if (!gateways || gateways.length === 0) {
              return;
            }

            const gatewayStatus = await refetchCurrentGatewayStatus(gateways);
            if (currentModal.data.source === 'source') {
              setGatewayData(gatewayStatus)
            } else {
              setDestinationGateways(gatewayStatus)
            }
          }}
          onSubmit={(data) => {
            const selectedDicomNodeAndGateway = data.gatewayId && data.dicomNodeId;
            if (!selectedDicomNodeAndGateway && data.gatewayId !== CloudPacs.id) {
              message.error("You must select Gateway and DICOM node")
              return;
            }

            if (currentModal.data.type === 'source') {
              setSourceGatewayId(data.gatewayId)
              setSourceDicomeNodeID(data.dicomNodeId);
            } else {
              setSelectedDestinationGateway(data.gatewayId);
              if (data.gatewayId === CloudPacs.id) {
                setDestinationDicome(null);
              } else {
                setDestinationDicome(data.dicomNodeId);
              }
            }

            onModalClose();
          }}
        />
      )}

      {currentModal.modal === MODAL_EDIT_DICOM_NODE && <EditDicomNodeModal data={currentModal.data} onClose={onModalClose} />}

      {currentModal.modal === MODAL_MANAGE_JOBS_MODAL && <ManageJobs onClose={onModalClose} />}

      {currentModal.modal === MODAL_EDIT_RULE_CLICK && <AIRuleModal selectedGatewayId={selectedSourceGatewayId} modelsData={modelsData} data={currentModal.data} onClose={onModalClose} />}

      {currentModal.modal === MODAL_CREATE_EDIT_AI_MODEL && <AIModal data={currentModal.data} modelsData={modelsData} onClose={onModalClose} />}

      <NavLogged
        onAdminControlClick={onAdminControlClick}
        onChangePasswordClick={onChangePasswordClick}
        onAIDeveloperClick={onAIDeveloperClick}
        onChangePersonalInformationClick={onChangePersonalInformationClick}
      />
      <Layout className="bg-white">{children}</Layout>
      <Footer />
      {contextHolder}
    </Layout>
  );
};
export default DashboardLayout;
