import { useEffect, useState, useContext } from 'react';
import { Worklist } from './Worklist'
import { AppContext } from '../../../libs/context';
import { GetWorklistByModelID } from '../../../apis/ai'
import { GetAllAiModel } from '../../../apis/ai'

export const DeveloperWorkList = ({ onViewLog }) => {
    const [worklist, setWorklist] = useState([]);
    const { store } = useContext(AppContext)
    const { getSelectedModelId } = store;
    const selectedModelId = getSelectedModelId();
    useEffect(() => {
        const fetchData = async () => {
            if (!selectedModelId) return;
            const response = await GetWorklistByModelID(selectedModelId);
            if (response.data) {
                setWorklist(response.data);
            }
        };

        fetchData();
    }, [selectedModelId])

    

    const [models, setModels] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const response = await GetAllAiModel(false);
            if (response.data) {
                setModels(response.data);
            }
        };
        fetchData();
    }, []);


    return <div><Worklist allowResend={false} requiredSelectGateway={false} models={models} worklist={worklist} onViewLog={onViewLog} /></div>
};
