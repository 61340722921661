import { serverAPIAxios, _generateAuthoriseHeader, _generateAuthoriseHeaderForm } from './init'

export const getListInputFilesGatewayId = async (gateway_id) => {
  try {
    const response = await serverAPIAxios.get(`/ai/input-files/${gateway_id}`, {
      headers: _generateAuthoriseHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error getListInputFilesGatewayId", error);
    throw error;
  }
};

export const getListOutputFilesGatewayId = async (gateway_id) => {
  try {
    const response = await serverAPIAxios.get(`/ai/output-files/${gateway_id}`, {
      headers: _generateAuthoriseHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error getListOutputFilesGatewayId", error);
    throw error;
  }
}

export const getDicomNodes = async (gateway_id) => {
  try {
    const response = await serverAPIAxios.get(`/ai//dicom-node/${gateway_id}`, {
      headers: _generateAuthoriseHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error getDicomNodes", error);
    throw error;
  }
}

export const createAiDicomNode = async (data) => {
  try {
    const response = await serverAPIAxios.post(`/ai/dicom-node`, data, {
      headers: _generateAuthoriseHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error createAiDicomNode", error);
    throw error;
  }
}

export const updateAiDicomNode = async (data) => {
  try {
    const response = await serverAPIAxios.put(`/ai/dicom-node`, data, {
      headers: _generateAuthoriseHeader(),
    });

    return response.data;
  } catch (error) {
    console.error("Error updateAiDicomNode", error);
    throw error;
  }
};

export const deleteDicomAiNode = async (gateway_id, node_id) => {
  try {
    const deleteBody = {
      gateway_id,
      node_id,
    };
    const response = await serverAPIAxios.delete(`/ai/dicom-node`, {
      data: deleteBody,
      headers: _generateAuthoriseHeader(),
    });

    return response.data;
  } catch (error) {
    console.error("Error deleteDicomNode", error);
    throw error;
  }
};

export const createAiDicomNodeCloud = async (gateway_id) => {
  try {
    const response = await serverAPIAxios.post(`/ai/dicom-node-ai-cloud`, { gateway_id }, {
      headers: _generateAuthoriseHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error createAiDicomNodeCloud", error);
    throw error;
  }
}

export const updateListenerAi = async (gateway_id, data) => {
  try {
    const response = await serverAPIAxios.put(`/listener/ai/${gateway_id}`, {
      ...data,
      service: 'ai'
    }, {
      headers: _generateAuthoriseHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error updateListenerAi", error);
    throw error;
  }
};

export const fetchAIListenerStatusById = async (gateway_id) => {
  try {
    const response = await serverAPIAxios.get(
      `/listener/ai/status/${gateway_id}`,
      {
        timeout: 5000,
        headers: _generateAuthoriseHeader(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetchListenerStatusById", error);
    return {
      data: {
        ip: [],
        status: "offline",
      },
    };
  }
};

export const restartListenerAi = async (gateway_id) => {
  try {
    const response = await serverAPIAxios.post(
      `/gateway/ai/restart`,
      {
        gateway_id,
      },
      {
        headers: _generateAuthoriseHeader(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error restartListenerAi", error);
    throw error;
  }
};

export const CreateAiModel = async (data) => {
  try {
    const response = await serverAPIAxios.post('/ai/model-ai/', data, {
      headers: _generateAuthoriseHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error CreateAiModel", error);
    throw error;
  }
};

export const GetAllModelById = async (id) => {
  try {
    const response = await serverAPIAxios.get(`/ai/model-ai/${id}`, {
      headers: _generateAuthoriseHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error GetAlModelById", error);
    throw error;
  }
};

export const GetAllAiModel = async (global = true) => {
  try {
    const response = await serverAPIAxios.get(`/ai/model-ai?global=${global}`, {
      headers: _generateAuthoriseHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error GetAllAiModel", error);
    throw error;
  }
};

export const DeleteAiModel = async (id) => {
  try {
    const response = await serverAPIAxios.delete(`/ai/model-ai/${id}`, {
      headers: _generateAuthoriseHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error DeleteAiModel", error);
    throw error;
  }
};

export const UpdateAiModel = async (id, data) => {
  try {
    const response = await serverAPIAxios.put(`/ai/model-ai/${id}`, data, {
      headers: _generateAuthoriseHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error UpdateAiModel", error);
    throw error;
  }
};

export const CreateRuleAI = async (gatewayid, data) => {
  try {
    const response = await serverAPIAxios.post(`/ai/rule-ai/${gatewayid}`, data, {
      headers: _generateAuthoriseHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error CreateRuleAI", error);
    throw error;
  }
};

export const GetRuleAIByGatewayID = async (id) => {
  try {
    const response = await serverAPIAxios.get(`/ai/rule-ai/${id}`, {
      headers: _generateAuthoriseHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error GetRuleAIByGatewayID", error);
    throw error;
  }
};

export const GetRuleByGatewayAndRuleId = async (gatewayid, ruleid) => {
  try {
    const response = await serverAPIAxios.get(`/ai/rule-ai/${gatewayid}/${ruleid}`, {
      headers: _generateAuthoriseHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error GetRuleByGatewayAndRuleId", error);
    throw error;
  }
};

export const DeleteRuleByGatewayIdAndRuleID = async (gatewayid, ruleid) => {
  try {
    const response = await serverAPIAxios.delete(`/ai/rule-ai/${gatewayid}/${ruleid}`, {
      headers: _generateAuthoriseHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error DeleteRuleByGatewayIdAndRuleID", error);
    throw error;
  }
};

export const UpdateRuleIDByGatewayIDAndRuleID = async (gatewayid, ruleid, data) => {
  try {
    const response = await serverAPIAxios.put(`/ai/rule-ai/${gatewayid}/${ruleid}`, data, {
      headers: _generateAuthoriseHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error UpdateRuleIDByGatewayIDAndRuleID", error);
    throw error;
  }
};

export const GetWorklistByGatewayID = async (gatewayid) => {
  try {
    const response = await serverAPIAxios.get(`/ai/gateway/worklist/${gatewayid}`, {
      headers: _generateAuthoriseHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error GetWorklistByGatewayID", error);
    throw error;
  }
};

export const GetWorklistByModelID = async (model_id) => {
  try {
    const response = await serverAPIAxios.get(`/ai/worklist/${model_id}`, {
      headers: _generateAuthoriseHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error GetWorklistByModelID", error);
    throw error;
  }
};

export const RenewAPIKey = async (model_id, data) => {
  try {
    const response = await serverAPIAxios.put(`/ai/model-ai/api-key/${model_id}`, {}, {
      headers: _generateAuthoriseHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error RenewAPIKey", error);
    throw error;
  }
};


export const ResendJob = async (job_id) => {
  try {
    const response = await serverAPIAxios.post(`/ai/gateway/resend/${job_id}`, {}, {
      headers: _generateAuthoriseHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error ResendJob", error);
    throw error;
  }
};


export const ResubmitJob = async (job_id) => {
  try {
    const response = await serverAPIAxios.post(`/ai/gateway/resubmit/${job_id}`, {}, {
      headers: _generateAuthoriseHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error ResubmitJob", error);
    throw error;
  }
};