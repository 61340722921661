import React, { useEffect } from 'react';
import { CreateRuleAI, UpdateRuleIDByGatewayIDAndRuleID } from '../../apis/ai'
import lodash from 'lodash'
import { Modal, Form, Input, Select, Button, message } from 'antd';
const { Option } = Select;
const { TextArea } = Input;

const studyDescriptionField = `00081030`;
const seriesDescriptionField = '0008103E';
const modalityField = '00080060'
const bodyPartExaminedField = '00180015'
const customField = "custom"

const AIRuleModal = ({ data, modelsData, onClose, selectedGatewayId }) => {
    const [form] = Form.useForm();
    const handleCancel = () => {
        onClose();
        form.resetFields();
    };
    const handleFormSubmit = async (values, isUpdate = false) => {
        const request = {
            "name": values.name,
            "model_id": values.model_id,
            "tags": [
                {
                    "key": "00081030", // Study Description 
                    "value": values.studyDescription,
                },
                {
                    "key": "00180015", // BodyPartExamined,
                    "value": values.bodyPartExamined,
                },
                {
                    "key": "0008103E", // Series Description 
                    "value": values.seriesDescription,
                },
                {
                    "key": "00080060", // Modality
                    "value": values.modality,
                },
            ]
        };

        if (values.custom) {
            request.tags.push({
                "key": "custom",
                "value": values.custom
            });
        }

        if (isUpdate) {
            await UpdateRuleIDByGatewayIDAndRuleID(selectedGatewayId, data.id, request);
        } else {
            await CreateRuleAI(selectedGatewayId, request);
        }

        form.resetFields();
        onClose();
    };

    const handleUpdate = () => {
        form.validateFields()
            .then(async (values) => {
                await handleFormSubmit(values, true);
                message.success("Rule AI successfully updated")
            })
            .catch(info => {
                console.log('Validate Failed:', info);
                message.error("Failed to update rule AI")
            })
    };

    const handleCreate = () => {
        form.validateFields()
            .then(async (values) => {
                await handleFormSubmit(values);
                message.success("Rule AI successfully created")
            })
            .catch(info => {
                console.error(info);
                message.error("Failed to create rule AI")
            });
    };

    useEffect(() => {
        if (data.name) {
            const studyDescription = data.tags.find((tag) => tag.key === studyDescriptionField)
            const seriesDescription = data.tags.find((tag) => tag.key === seriesDescriptionField)
            const modality = data.tags.find((tag) => tag.key === modalityField)
            const bodyPartExamined = data.tags.find((tag) => tag.key === bodyPartExaminedField)
            const model_id = lodash.get(data, 'model.id', "N/A")
            const custom = data.tags.find((tag) => tag.key === customField)
            const customValue = lodash.get(custom, 'value', "")

            form.setFieldsValue({
                "name": data.name,
                "model_id": model_id,
                'modality': modality.value,
                'seriesDescription': seriesDescription.value,
                'studyDescription': studyDescription.value,
                'bodyPartExamined': bodyPartExamined.value,
                "custom": customValue
            })
        }
    }, []);

    const modelOptions = Object.values(modelsData).map(model => ({
        value: model.id,
        label: model.name
    }));

    const validateJSON = (_, value) => {
        if (!value) return Promise.resolve();
        try {
            JSON.parse(value);
            return Promise.resolve();
        } catch (error) {
            return Promise.reject('Please enter valid JSON');
        }
    };


    return (
        <Modal
            title="Create Rule"
            visible={true}
            onCancel={handleCancel}
            footer={[
                <Button key="cancel" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button className="bg-blue-500 hover:bg-blue-700 text-white font-bold rounded" key="done" type="primary" onClick={!data.id ? handleCreate : handleUpdate}>
                    Done
                </Button>,
            ]}
        >
            <p className='text-sm mb-2.5'>Note: Enter * to forward all</p>
            <Form form={form} layout="vertical">
                <Form.Item
                    name="name"
                    label="Name"
                    rules={[{ required: true, message: 'Please input the name!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="model_id"
                    label="AI Model"
                    rules={[{ required: true, message: 'Please select an AI model!' }]}
                >
                    <Select placeholder="Select an AI model">
                        {modelOptions.map(option => (
                            <Option key={option.value} value={option.value}>
                                {option.label}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="studyDescription"
                    label="Study Description"
                    rules={[{ required: true, message: 'Please input the study description!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="seriesDescription"
                    label="Series Description"
                    rules={[{ required: true, message: 'Please input the series description!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="modality"
                    label="Modality"
                    rules={[{ required: true, message: 'Please input the modality!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="bodyPartExamined"
                    label="Body Part Examined"
                    rules={[{ required: true, message: 'Please input the Body Part Examined!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="custom"
                    label="Custom"
                    rules={[{ required: false }, { validator: validateJSON }]}
                >
                    <TextArea
                        rows={10}
                        placeholder="Enter valid JSON object e.g. {'key': 'value'}"
                        maxLength={5000}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AIRuleModal;