import { Layout, Divider, message } from "antd";
import { StudiesList } from "../components/Studies/StudiesList";
import SearchFilter from "../components/Filters/Studies"
import { findDataFromNode, buildQuery } from "../../apis/node";
import { useState, useEffect } from "react";
import { formatLayout } from '../../libs/date'
import lodash from 'lodash';
import SelectWithModalWrapper from '../components/GatewayDicom/GatewayDicomSelectWrapper'
import {
  CLOUD_PACS_MODE,
  GATEWAY_ONLINE_STATUS,
} from "../constants/dicomNode";
import _ from "lodash";
import JobWrapper from '../components/Jobs/JobWrapper'
import { useContext } from 'react'
import { AppContext } from '../../libs/context'

export const Dashboard = () => {
  const { store } = useContext(AppContext)
  const { clearAllCBKeys, currentImageSharingStudyData, setSelectedSeries,
    setImageSharingStudyData, setSeriesData,
    selectedSourceGatewayId, selectedDestinationGatewayId,
    selectedDestinationDicomId,
    currentSelectedSeries, selectedSourceDicomNodeId } = store

  const { hasCompletedOnboarding, setOnboardingComplete } = store;
  const { setLoadingDestinationGateway } = store;
  const { setLoadingSourceDicomNodes, setLoadingDestinationDicomNodes } = store;

  const isSelectedCloudPacs = selectedDestinationGatewayId === CLOUD_PACS_MODE;
  const selectedDestinationGatewayAndDicom = (selectedDestinationGatewayId && selectedDestinationDicomId) || isSelectedCloudPacs
  const selectSourceGatewayAndDicomNode = selectedSourceGatewayId && selectedSourceDicomNodeId;

  const isSelectedAllGatewayDicomSourceAndDestination = selectedDestinationGatewayAndDicom && selectSourceGatewayAndDicomNode


  const onSelectStudyRecord = (records) => {
    setSelectedSeries(records);
  };

  const [isFromSearchLoading, setFromSearchLoading] = useState(false);
  const onSearchClick = (filters) => {
    let studyDateRange = null;
    let birthday = null;
    if (filters.studyDateRange) {
      studyDateRange = filters.studyDateRange.map((date) => formatLayout(date));
    }

    if (filters.birthday) {
      birthday = formatLayout(filters.birthday);
    }

    searchStudies({
      ...filters,
      birthday,
      studyDate: filters.studyDate ? `${studyDateRange[0]}-${studyDateRange[1]}` : null,
    })
  }

  const searchStudies = async ({
    patientName,
    patientID,
    accessionNumber,
    studyDescription,
    referringPhysicianName,
    modality,
    birthday,
    studyDate,
  }) => {
    if (
      lodash.isEmpty(patientName) &&
      lodash.isEmpty(patientID) &&
      lodash.isEmpty(accessionNumber) &&
      lodash.isEmpty(studyDescription) &&
      lodash.isEmpty(referringPhysicianName) &&
      lodash.isEmpty(birthday)
    ) {
      return; // Don't do anything if all fields are empty, undefined, or have a string length of 0
    }

    if (isFromSearchLoading) return;
    setFromSearchLoading(true);
    setImageSharingStudyData([]);
    setSeriesData([]);
    setSelectedSeries([]);
    clearAllCBKeys();
    try {
      const query = {
        patientName,
        patientID,
        accessionNumber,
        studyDescription,
        referringPhysicianName,
        modality,
        birthday,
        studyDate,
      };

      if (!lodash.isEmpty(studyDate) || !lodash.isEmpty(modality)) {
        query.type = 'SERIES';
      }

      const tagsFilter = buildQuery(query);

      const response = await findDataFromNode(
        selectedSourceGatewayId,
        selectedSourceDicomNodeId,
        tagsFilter
      );
      if (!response.data || response.data.length === 0) {
        message.info('No result found');
        return;
      }

      if (response.data) {
        const filteredGroupStudy = response.data.reduce((acc, data) => {
          const foundData = acc.filter(
            (item) => item.studyInstanceUID === data.studyInstanceUID
          );
          if (foundData.length === 0) {
            return [...acc, data];
          }

          return acc;
        }, []);

        setImageSharingStudyData([
          ...filteredGroupStudy,
        ]);
      }
    } catch (e) {
      const errMessage = lodash.get(e, "response.data.message", null);
      if (errMessage) {
        message.error(errMessage);
      } else {
        message.info('No result found');
      }
    } finally {
      setFromSearchLoading(false);
    }
  };


  const isSelectedSeries = currentSelectedSeries.length > 0;
  const shouldShowSearchbox = isSelectedAllGatewayDicomSourceAndDestination || hasCompletedOnboarding;
  const shouldShowStudiesList = isSelectedAllGatewayDicomSourceAndDestination || hasCompletedOnboarding;
  const shouldShowJob = isSelectedSeries || hasCompletedOnboarding;

  useEffect(() => {
    const setUserOnboard = () => {
      if (isSelectedSeries && isSelectedAllGatewayDicomSourceAndDestination) {
        setOnboardingComplete();
      }
    }
    setUserOnboard();
  }, [isSelectedAllGatewayDicomSourceAndDestination, isSelectedSeries])

  return (
    <Layout className="bg-white flex-row flex-col">
      <div className="p-4 mx-0 center mx-auto w-[1300px]">
        <h1 className="text-2xl font-bold text-center">Image Transfer</h1>
        <Divider className="border-gray-380" />
        <SelectWithModalWrapper />

        {shouldShowSearchbox &&
          <div className="w-full">
            <Divider className="border-gray-300" />
            <div className="mb-4 max-w-[1000px]">
              <SearchFilter label='' isLoading={isFromSearchLoading} searchLabel='Search From Source' onChange={onSearchClick} />
              <label className="text-gray-500 text-sm">Note : Select DICOM images to send</label>
            </div>
          </div>}
        {shouldShowStudiesList &&
          <div className="w-full">
            <StudiesList
              nodeID={selectedSourceDicomNodeId}
              gatewayID={selectedSourceGatewayId}
              onSelect={onSelectStudyRecord}
              data={currentImageSharingStudyData}
            />
            <Divider className="border-gray-380" />
          </div>
        }
        {shouldShowJob && <JobWrapper />}
      </div>
    </Layout >
  );
};
