import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useCallback, useState } from 'react';
import AppLayout from './layouts/HomeLayout';
import DashboardLayout from './layouts/DashboardLayout';
import ViewDetailLayout from './layouts/ViewDetailLayout';
import { LoginPage, SignupPage, ForgotPasswordPage } from './pages/Users';
import { DashboardPage, StudiesPage } from './pages/Dashboard';
import { BatchTransferingPage } from './pages/BatchTransfering';
import { AccessControlPage, AdminControlPage } from './pages/Permission';
import { AIPage, AIDeveloperPage } from './pages/AI';
import { ShareDetail } from './pages/Dashboard';
import { Spin } from 'antd';
import { useEffect } from 'react'
import { AppContext } from './libs/context';
import { createImageSharingStore, createExcelFileStore, createBatchTrasnferingStore, createAIjobStore } from './stores/index'
import { useAuth0 } from '@auth0/auth0-react';
import Auth0Provider from './Auth0Wrapper'
import { authorisedUser } from './apis/apis'

// Simplifies route protection
const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, isLoading, getAccessTokenSilently, user } = useAuth0();
  const [isTokenSet, setIsTokenSet] = useState(false);

  const fetchToken = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      localStorage.setItem('token', token);
      if (user) {
        await authorisedUser(user);
      }
      setIsTokenSet(true);
    } catch (error) {
      console.error('Error fetching token:', error);
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchToken();
    }
  }, [isAuthenticated]);

  if (!isLoading && !isAuthenticated) {
    return <Navigate to="/dashboard/login" replace />;
  }

  if (isLoading || !isAuthenticated || !isTokenSet) {
    return <span className='p-2.5 pt-2.5'>Loading <Spin className='p-1.5' /></span>;
  }

  return isAuthenticated ? children : <Navigate to="/dashboard/login" replace />;
};

const PublicRoute = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth0();
  if (isLoading) return <Spin></Spin>

  return !isAuthenticated ? children : <Navigate to="/dashboard" replace />;
};

// Layout wrapper for simplifying layout usage
const LayoutWrapper = ({ layout: Layout, store, component: Component, ...rest }) => {
  const [currentModal, setCurrentModal] = useState({
    modal: null,
    data: null,
  });

  return <AppContext.Provider value={{ currentModal, setCurrentModal, store }}>
    <Layout>
      <Component {...rest} />
    </Layout>
  </AppContext.Provider>
}

function App() {
  const imageSharingStore = createImageSharingStore();
  const batchTransferingStore = createBatchTrasnferingStore();
  const excelFileStore = createExcelFileStore();
  const aiJobStore = createAIjobStore();
  return (
    <BrowserRouter>
      <Auth0Provider>
        <Routes>
          <Route path="/" index element={<PublicRoute><Navigate to="/dashboard/login" replace /></PublicRoute>} />
          <Route path="/dashboard/login" element={<PublicRoute><LayoutWrapper component={LoginPage} layout={ViewDetailLayout} /></PublicRoute>} />
          <Route path="/dashboard/forgot" element={<PublicRoute><LayoutWrapper component={ForgotPasswordPage} layout={AppLayout} /></PublicRoute>} />
          <Route path="/dashboard/signup" element={<PublicRoute><LayoutWrapper component={SignupPage} layout={AppLayout} /></PublicRoute>} />
          <Route path="/dashboard" element={<ProtectedRoute><LayoutWrapper store={{ ...imageSharingStore }} component={DashboardPage} layout={DashboardLayout} /></ProtectedRoute>} />
          <Route path="/dashboard/studies" element={<ProtectedRoute><LayoutWrapper store={{ ...imageSharingStore }} component={StudiesPage} layout={DashboardLayout} /></ProtectedRoute>} />
          <Route path="/dashboard/batch-transfering" element={<ProtectedRoute><LayoutWrapper store={{ ...batchTransferingStore, ...excelFileStore }} component={BatchTransferingPage} layout={DashboardLayout} /></ProtectedRoute>} />
          <Route path="/dashboard/permission" element={<ProtectedRoute><LayoutWrapper store={{ ...imageSharingStore }} component={AccessControlPage} layout={DashboardLayout} /></ProtectedRoute>} />
          <Route path="/dashboard/admin-control" element={<ProtectedRoute><LayoutWrapper store={{ ...imageSharingStore }} component={AdminControlPage} layout={DashboardLayout} /></ProtectedRoute>} />
          <Route path="/dashboard/ai" element={<ProtectedRoute><LayoutWrapper store={{ ...aiJobStore }} component={AIPage} layout={DashboardLayout} /></ProtectedRoute>} />
          <Route path="/dashboard/ai-developer" element={<ProtectedRoute><LayoutWrapper store={{ ...aiJobStore }} component={AIDeveloperPage} layout={DashboardLayout} /></ProtectedRoute>} />
          <Route path="/dashboard/study/" element={<ProtectedRoute><LayoutWrapper store={{ ...aiJobStore }} component={AIPage} layout={DashboardLayout} /></ProtectedRoute>} />
          <Route path="/dashboard/detail" element={<LayoutWrapper store={{ ...imageSharingStore }} component={ShareDetail} layout={ViewDetailLayout} />} />
        </Routes>
      </Auth0Provider>
    </BrowserRouter>
  );
}

export default App;
