import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { appConfig } from './config/config';

const Auth0ProviderWithHistory = ({ children }) => {
  const domain = appConfig.auth0.domain;
  const clientId = appConfig.auth0.clientId;
  const audience = appConfig.auth0.audience;
  const navigate = useNavigate();

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  const providerConfig = {
    domain,
    clientId,
    onRedirectCallback,
    authorizationParams: {
      redirect_uri: window.location.origin + '/dashboard/login',
      ...(audience ? { audience } : null),
    },
  };

  return (
    <Auth0Provider
      {...providerConfig}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;