import { Table, Button, Input, Select, Popconfirm } from "antd";
import { PlusOutlined } from '@ant-design/icons'
import { useEffect, useState, useContext } from "react";
import { AppContext } from '../../../libs/context';
import { MODAL_CREATE_EDIT_AI_MODEL } from '../../../../src/pages/constants/modals';
import { GetAllAiModel, DeleteAiModel, RenewAPIKey } from '../../../apis/ai'
import { message } from 'antd'
import _ from 'lodash'
const { Search } = Input;

const innerTablePagination = {
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
};

export const AiModels = () => {
    const { store, setCurrentModal } = useContext(AppContext)
    const [searchTerm, setSearchTerm] = useState("");

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            render: (text, record) => {
                return <p className="w-[100px] text-wrap">{record.id}</p>
            }
        },
        {
            title: "Name",
            dataIndex: "name",
            render: (text, record) => {
                return <p className="w-[100px] text-wrap">{record.name}</p>
            }
        },
        {
            title: "Description",
            dataIndex: "description",
            render: (text, record) => {
                return <p className="w-[100px] text-wrap">{record.description}</p>
            }
        },
        {
            title: "Version",
            dataIndex: "version",
            render: (text, record) => {
                return <p className="w-[10px] text-wrap">{record.version}</p>
            }
        },
        {
            title: "API key",
            dataIndex: "apiKey",
            render: (text, record) => {
                return <p className="w-[150px] text-wrap">{record.apikey}</p>
            }
        },
        {
            title: "Global",
            dataIndex: "global",
            render: (text, record) => {
                return <p className="w-[150px] text-wrap">{record.global.toString()}</p>
            }
        },
        {
            title: "Action",
            dataIndex: "action",
            className: "w-[110px]",
            render: (text, record) => {
                return <div className="flex">
                    <Button onClick={() => {
                        onEditAi(record);
                    }} className="mr-2.5">Edit</Button>
                    <Popconfirm
                        okButtonProps={{ className: "bg-blue-600" }}
                        title="Do you want to cancel?"
                        onConfirm={() => {
                            onDeleteAi(record);
                        }}
                    >
                        <Button className="mr-2.5">Delete</Button>
                    </Popconfirm>
                    <Button onClick={() => {
                        onRenewApiKey(record);
                    }} className="mr-2.5">Renew API</Button>
                </div>
            }
        },
    ]


    const [models, setModels] = useState([]);
    let filteredData = [];
    if (models.length > 0) {
        filteredData = models.filter((item) =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }
    
    useEffect(() => {
        const fetchData = async () => {
            const response = await GetAllAiModel(false);
            setModels(response.data);
        };
        fetchData();
    }, []);

    const onCreateAi = () => {
        setCurrentModal({
            modal: MODAL_CREATE_EDIT_AI_MODEL,
        });
    }

    const onEditAi = (data) => {
        setCurrentModal({
            modal: MODAL_CREATE_EDIT_AI_MODEL,
            data
        });
    }

    const onDeleteAi = async (data) => {
        try {
            await DeleteAiModel(data.id);
            const response = await GetAllAiModel(false);
            setModels(response.data);
        } catch (e){
            console.log(e);
        }
    }

    const onRenewApiKey = async (data) => {
        try {
           await RenewAPIKey(data.id, data);
           message.success('Renew API key successfully');
        } catch (e) {
            message.error('Renew API key error');
            console.log(e);
        }
    }

    return (
        <div>
            <div className="flex justify-between w-full">
                <div className="flex mb-2.5">
                    <Button onClick={() => {
                        onCreateAi();
                    }} icon={<PlusOutlined />} className="mr-2.5">Create AI</Button>
                    <Search
                        placeholder="Search name"
                        className="mr-2.5"
                        onSearch={value => setSearchTerm(value)}
                        style={{ width: 400 }}
                    />
                </div>
            </div>

            <Table
                columns={columns}
                dataSource={filteredData}
                pagination={innerTablePagination}
                rowKey={'id'}
            />
        </div>

    );
};
