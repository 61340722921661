import React, { useState, useEffect } from 'react';
import { Modal, Button, Upload, Input, List, Space, message, Form } from 'antd';
import { UploadOutlined, FileTextOutlined, EyeOutlined } from '@ant-design/icons';
import { fetchReports, uploadReportAttachment, submitStudyReport, downloadReportFile, deleteComment } from '../../apis/apis'
import { Typography } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import { getTokenData } from "../../libs/jwt";

const { TextArea } = Input;

const ReportModal = ({ studyId, studyLink, isOpen, closable = true, onClose, allowDeleteAll = false }) => {
    const [reports, setReports] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const loadReports = async () => {
        try {
            const resp = await fetchReports(studyId);
            if (resp.data) {
                setReports(resp.data);
            }
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        if (studyId && isOpen) {
            loadReports();
        }

    }, [studyId, isOpen]);

    const [auth0UserID, setUserID] = useState("");
    const onFinish = async (values) => {
        try {

            console.log(fileList);
            const reportFileId = fileList && fileList.length > 0 ? fileList[0].url : null;
            await submitStudyReport(
                fileList[0],
                values.comments,
                values.author_name, // Assuming you have these form fields
                reportFileId,
                studyId,
                auth0UserID
            );

            message.success('Report submitted successfully');
            form.resetFields();
            setFileList([]);
            loadReports();
        } catch (error) {
            console.error('Failed to submit report:', error);
            message.error('Failed to submit report');
        }
    };

    const uploadProps = {
        beforeUpload: async (file) => {
            try {
                setLoading(true);
                const response = await uploadReportAttachment(file, studyId);
                setFileList([{
                    uid: response.files[0].filename,
                    name: response.files[0].originalname,
                    status: 'done',
                    url: response.files[0].path
                }]);
                message.success('Report uploaded successfully');
                return false; // Prevent default upload behavior
            } catch (error) {
                message.error('Failed to upload report');
                return false;
            } finally {
                setLoading(false);
            }
        },
        onRemove: () => {
            setFileList([]);
            message.success('File removed successfully');
            return true;
        },
        fileList,
        maxCount: 1,
        multiple: false,
        showUploadList: true
    };

    useEffect(() => {
        const fetchUserID = () => {
            try {
                const auth0UserID = getTokenData().user_id;
                setUserID(auth0UserID);
            } catch (e) {
                console.error(e);
                const user_id = localStorage.getItem('user_id');
                if (user_id && user_id.length >= 0) {
                    setUserID(user_id)
                } else {
                    localStorage.setItem('user_id', uuidv4());
                    setUserID(user_id);
                }
            }
        }

        fetchUserID();
    }, []);


    const deleteCommentById = async (id) => {
        try {
            await deleteComment(id);
            message.success("Comment delete successfully");
            loadReports();

        } catch (e) {
            message.error("Failed to delete comment");
        }
    }

    useEffect(() => {
        if (!studyLink || studyLink.length === 0) return;
        window.open(studyLink);
    }, []);

    return (
        <Modal
            title={
                <div className="flex">
                    <span className='mt-0.5'>Study Reports</span>
                    {studyLink && (
                        <Button
                            type="link"
                            icon={<EyeOutlined />}
                            onClick={() => {
                                window.open(studyLink)
                            }}
                            className="text-blue-600 hover:text-blue-700"
                        >
                            View Study Detail
                        </Button>
                    )}
                </div>
            } open={isOpen}
            onCancel={onClose}
            width={800}
            closable={closable}
            footer={closable ? [
                <Button key="cancel" onClick={onClose}>
                    Close
                </Button>,
            ] : []}
        >
            <Space direction="vertical" style={{ width: '100%' }} size="large">
                <Form
                    form={form}
                    onFinish={onFinish}
                    layout="vertical"
                >
                    <Form.Item
                        name="file"
                    >
                        <Upload {...uploadProps}>
                            <Button icon={<UploadOutlined />}>Select Report File</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item
                        name="author_name"
                        label="Author name"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter author name',
                            },
                            {
                                whitespace: true,
                                message: 'Author name cannot be empty spaces',
                            },
                            {
                                min: 2,
                                message: 'Author name must be at least 2 characters',
                            }
                        ]}
                    >
                        <Input placeholder="Author name..." />
                    </Form.Item>

                    <Form.Item
                        name="comments"
                        label="Comments"
                        rules={[
                            {
                                required: !fileList.length,
                                message: 'Please add comments or select a file'
                            }
                        ]}
                    >
                        <TextArea rows={4} placeholder="Add comments..." />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                            className="bg-blue-500 hover:bg-blue-600"
                        >
                            Submit Report
                        </Button>
                    </Form.Item>
                </Form>
                <List
                    itemLayout="horizontal"
                    dataSource={reports}
                    renderItem={(item) => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={<FileTextOutlined />}
                                title={item.author_name}
                                description={
                                    <div>
                                        <p>{item.comments}</p>
                                        {item.report_file_id && (
                                            <div>
                                                <Typography.Link
                                                    onClick={async (e) => {
                                                        e.preventDefault();
                                                        try {
                                                            const blob = await downloadReportFile(item.report_file_id);

                                                            // Extract filename from path or use default
                                                            const fileName = item.report_file_id.split('/').pop() || 'report.pdf';

                                                            // Create download link
                                                            const url = window.URL.createObjectURL(blob);
                                                            const link = document.createElement('a');
                                                            link.href = url;
                                                            link.setAttribute('download', fileName);
                                                            document.body.appendChild(link);
                                                            link.click();
                                                            link.remove();
                                                            window.URL.revokeObjectURL(url);
                                                        } catch (error) {
                                                            message.error('Failed to download file');
                                                            console.error(error);
                                                        }
                                                    }}
                                                    className="mt-2 inline-flex items-center"
                                                >
                                                    <DownloadOutlined className="mr-1" />
                                                    Download Report
                                                </Typography.Link>
                                                <br />
                                            </div>
                                        )}

                                        <small>
                                            {new Date(item.created_at).toLocaleString()}
                                            {(allowDeleteAll || item.author_auth0_user_id === auth0UserID) &&
                                                <span onClick={() => {
                                                    deleteCommentById(item.id);
                                                }} className='ml-2.5 cursor-pointer text-sm'>
                                                    Delete
                                                </span>
                                            }
                                        </small>
                                    </div>
                                }
                            />
                        </List.Item>
                    )}
                />
            </Space>
        </Modal>
    );
};

export default ReportModal;