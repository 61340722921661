import React, { useEffect } from 'react';
import { Modal, Form, Input, Select, Button, message } from 'antd';
import { CreateAiModel } from "../../apis/ai";

const AIModal = ({ data, onClose }) => {
    const [form] = Form.useForm();
    const handleCancel = () => {
        onClose();
        form.resetFields();
    };
    const handleFormSubmit = async (values) => {
        try {
            await CreateAiModel(values);
            form.resetFields();
            onClose();
            message.success(data ? "AI Model updated successfully" : "AI Model created successfully");
        } catch (error) {
            message.error("Operation failed");
        }
    };


    useEffect(() => {
        if (data) {
            form.setFieldsValue({
                name: data.name,
                description: data.description,
                email: data.email,
                version: data.version
            });
        }
    }, [data, form]);

    const onSubmit = () => {
        try {
            form.validateFields()
                .then(async (values) => {
                    await handleFormSubmit(values);
                })
                .catch(info => {
                    console.error(info);
                    message.error("Failed to create rule AI")
                });

        } catch (e) {
            console.error(e);
            message.error("Failed to update AI model");
        }
    }

    return (
        <Modal
            title={data ? "Update AI Model" : "Create AI Model"}
            visible={true}
            onCancel={handleCancel}
            footer={[
                <Button key="cancel" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button onClick={onSubmit} className="bg-blue-500 hover:bg-blue-700 text-white font-bold rounded" key="done" type="primary" >
                    {data ? 'Update' : 'Create'}

                </Button>,
            ]}
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    name="name"
                    label="Name"
                    rules={[{ required: true, message: 'Please input the name!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="description"
                    label="Description"
                    rules={[{ required: true, message: 'Please input the description!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="version"
                    label="Version"
                    rules={[{ required: true, message: 'Please input the version!' }]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AIModal;