import React, { useState, useEffect } from 'react';
import { Modal, Button, Input, message } from 'antd';
import {
    generateShareLink,
} from "../../apis/apis";

const buildLinkWithToken = (token) => {
    const currentDomain = window.location.origin; // Gets protocol + domain + port
    return `${currentDomain}/dashboard/detail?token=${token}`;
};
const Share = ({ isVisible, onClose, resourceId }) => {
    const [shareLink, setShareLink] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (isVisible) {
            generateLink();
        }
    }, [isVisible, resourceId]);

    const generateLink = async () => {
        try {
            setLoading(true);
            const response = await generateShareLink(resourceId)
            if (response) {
                setShareLink(buildLinkWithToken(response.token));
                setPassword(response.password);
            }
        } catch (error) {
            console.log(error);
            message.error('Failed to generate share link');
        } finally {
            setLoading(false);
        }
    };

    const copyToClipboard = async (input) => {
        try {
            await navigator.clipboard.writeText(input);
            message.success('Copied to clipboard!');
        } catch (error) {
            message.error('Failed to copy');
        }
    };

    return (
        <Modal
            title="Sharing"
            open={isVisible}
            onCancel={onClose}
            footer={[
                <Button key="close" onClick={onClose}>
                    Close
                </Button>,
                <Button
                    key="copy-link"
                    type="primary"
                    className={`text-sm text-white rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 
                        ${!shareLink
                            ? 'bg-blue-300 cursor-not-allowed'
                            : 'bg-blue-600 hover:bg-blue-700'
                        }`}
                    onClick={() => {
                        copyToClipboard(shareLink);
                    }}
                    disabled={!shareLink}
                >
                    Copy Link
                </Button>,
                <Button
                    key="copy-password"
                    type="primary"
                    className={`text-sm text-white rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 
                    ${!shareLink
                            ? 'bg-blue-300 cursor-not-allowed'
                            : 'bg-blue-600 hover:bg-blue-700'
                        }`}
                    onClick={() => {
                        copyToClipboard(password);
                    }}
                >
                    Copy Passcode
                </Button>

            ]}
        >
            <p className='text-xs'>
                Note : The link and passcode are displayed only once and will expire after 7 days
            </p>
            <p className='mt-2.5 font-semibold mb-1.5'>Link : </p>
            <Input.TextArea
                value={shareLink}
                placeholder={loading ? 'Generating link...' : 'Share link will appear here'}
                readOnly
                autoSize={{ minRows: 2 }}
            />
            <p className='mt-2.5'><span className='font-semibold'>Passcode : </span>{password}</p>
        </Modal>
    );
};

export default Share;