import { Table, Button, Input, Select, message } from "antd";
import { useEffect, useState, useContext } from "react";
import { AppContext } from '../../../libs/context';
import { ResendJob, ResubmitJob } from '../../../apis/ai';
import _ from 'lodash'
const { Search } = Input;

const innerTablePagination = {
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
};

const fixedColumns = [
    {
        title: "Patient ID",
        dataIndex: "PatientID",
        className: "w-[100px]",
    },
    {
        title: "Accession Number",
        dataIndex: "AccessionNumber",
        className: "w-[90px]",
        render: (text, record) => {
            return <p className="w-[100px] text-wrap">{record.AccessionNumber}</p>
        }
    },
    {
        title: "StudyInstanceUID",
        dataIndex: "StudyInstanceUID",
        render: (text, record) => {
            return <p className="w-[100px] text-wrap">{record.StudyInstanceUID}</p>
        }
    },
    {
        title: "Study Description",
        dataIndex: "StudyDescription",
        className: "w-[100px]",
        render: (text, record) => {
            return <p className="w-[100px] text-wrap">{record.StudyDescription}</p>
        }
    },
    {
        title: "Status",
        dataIndex: "Status",
        className: "w-[100px]"
    },
    {
        title: "Priority",
        dataIndex: "Priority",
        className: "w-[100px]"
    },
    {
        title: "Information",
        dataIndex: "Information",
        className: "w-[100px]",
        render: (text, record) => {
            return <p className="w-[50px] text-wrap">{record.Information}</p>
        }
    },
    {
        title: "createdAt",
        dataIndex: "createdAt",
        className: "w-[100px]",
        render: (text, record) => {
            return <p className="w-[50px] text-wrap">{record.createdAt}</p>
        }
    },
    {
        title: "TurnOverTime",
        dataIndex: "TurnOverTime",
        className: "w-[50px]",
        render: (text, record) => {
            return <p className="w-[50px] text-wrap">{record.TurnOverTime}</p>
        }
    },
    {
        title: "Gateway name",
        dataIndex: "gateway_name",
        className: "w-[50px]"
    },
    {
        title: "Model AI name",
        dataIndex: "model_ai_name",
        className: "w-[100px]"
    },
]

export const Worklist = ({ allowResend = false, requiredSelectGateway = false, onViewLog, models = [], worklist = [] }) => {
    const { store } = useContext(AppContext)
    const { gatewayData } = store;
    const { getSelectedWorklistGatewayId, setSelectedWorklistGatewayId, setSelectedModelId } = store;

    const columns = [
        ...fixedColumns,
        {
            title: "View logs",
            dataIndex: "viewLog",
            className: "w-[150px]",
            render: (text, record) => {
                let log = record.Log;
                if (_.isEmpty(log) || _.isNull(log)) {
                    log = "No data";
                } else {
                    log = JSON.stringify(log);
                }

                return <Button
                    onClick={() => onViewLog(log)}
                    className="bg-blue-500 hover:bg-blue-700"
                    type="primary">View log
                </Button>
            },
        },
    ];

    const [searchTerm, setSearchTerm] = useState("");
    const selectedGatewayId = getSelectedWorklistGatewayId();

    const handleChange = (gatewayId) => {
        setSelectedWorklistGatewayId(gatewayId);
    };

    const handleModelChange = (modelId) => {
        setSelectedModelId(modelId);
    };

    useEffect(() => {
        if (selectedGatewayId === null && gatewayData && gatewayData.length > 0) {
            setSelectedWorklistGatewayId(gatewayData[0].id);
        }
    }, [gatewayData])


    const filteredData = worklist.filter((item) =>
        item.PatientName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const [selectedRows, setSelectedRows] = useState([]);
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        getCheckboxProps: (record) => ({
            name: record.name,
        }),
    };

    const onResendResult = async () => {
        if (selectedRows.length === 0) {
            return;
        }

        try {
            for (let i = 0; i < selectedRows.length; i++) {
                const row = selectedRows[i];
                await ResendJob(row.id);
            }
            message.success("Resend job successfully");
        } catch (e) {
            message.error("Resend job failed");
            console.log(e);
        }
    }

    const onResubmitJob = async () => {
        if (selectedRows.length === 0) {
            return;
        }
        try {
            for (let i = 0; i < selectedRows.length; i++) {
                const row = selectedRows[i];
                await ResubmitJob(row.id);
            }
            message.success("Resubmit job successfully");
        } catch (e) {
            message.error("Resubmit job failed");
            console.log(e);
        }
    }
    return (
        <div>
            <div className="flex justify-between w-full">
                <div className="flex">
                    <Search
                        placeholder="Search patient name"
                        className="mr-2.5"
                        onSearch={value => setSearchTerm(value)}
                        style={{ width: 400 }}
                    />
                    {requiredSelectGateway && <Select
                        showSearch
                        className="mb-2.5"
                        defaultValue={gatewayData && gatewayData.length > 0 ? gatewayData[0].id : null}
                        onChange={handleChange}
                        placeholder="Select a gateway"
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={gatewayData.map((gateway) => {
                            return {
                                label: gateway.name,
                                value: gateway.id,
                                key: gateway.id,
                            }
                        })}
                    />}
                    {models && models.length > 0 && (
                        <Select
                            showSearch
                            className="mb-2.5 ml-2.5"
                            placeholder="Select a model"
                            onChange={handleModelChange}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={models.map((model) => {
                                return {
                                    label: model.name,
                                    value: model.id,
                                    key: model.id,
                                }
                            })}
                        />
                    )}
                </div>
                {allowResend && <div className="flex">
                    <Button onClick={onResubmitJob} primary>Resubmit job</Button>
                    <Button onClick={onResendResult} className="ml-2.5" primary>Resend Result</Button>
                </div>}


            </div>

            <Table
                columns={columns}
                dataSource={filteredData}
                pagination={innerTablePagination}
                rowKey={(record) => record.id}
                rowSelection={{
                    type: 'checkbox',
                    ...rowSelection
                }}
            />
        </div>

    );
};
